import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// React icons
import { FaGithub, FaFacebook, FaTwitter, FaLinkedin, FaGooglePlay, FaCoffee } from 'react-icons/fa';
import { SiFitbit, SiBuymeacoffee } from 'react-icons/si';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import team from "assets/img/cartoon.png";
import bmcButton from "assets/img/bmc-button.png";

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>About me</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} lg={8} className={classes.itemGrid}>
                  <img src={team} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Jason M. Casebier
                  <br />
                  <small className={classes.smallTitle}>People Leader · Problem Solver · Tech Professional</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    I'm a husband, father, and military veteran with a passion for design, development, and the pursuit of knowledge. My family is my greatest treasure. My wife is my best friend, and our three beautiful daughters are my inspiration. I have many interests, from astronomy to psychology, and I often let curiosity drive my learning. I'm experienced with business process automation, Workday integrations, and web and mobile application development. I obtained my bachelor’s degree in computer information technology from Austin Peay State University.
                  </p>
                </CardBody>
                <CardFooter className={classes.justifyCenter}>
                  <Button
                    href="http://linkedin.com/in/jmcasebier"
                    target="_blank"
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaLinkedin />
                  </Button>
                  <Button
                    href="http://github.com/jmcasebier"
                    target="_blank"
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaGithub />
                  </Button>
                  <Button
                    href="http://facebook.com/jmcasebier"
                    target="_blank"
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaFacebook />
                  </Button>
                  <Button
                    href="http://play.google.com/store/apps/developer?id=jmcasebier"
                    target="_blank"
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaGooglePlay />
                  </Button>
                  {/*<Button
                    href="https://gallery.fitbit.com/developer/a0b2b60e-6c7c-407d-919a-5d295192ba8a"
                    target="_blank"
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <SiFitbit />
                  </Button>*/}
                  {/*<Button
                    href="https://www.buymeacoffee.com/jmcasebier"
                    target="_blank"
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <SiBuymeacoffee />
                  </Button>*/}
                </CardFooter>
                {/*<Button
                  className={classes.coffeeButton}
                  color="primary"
                  size="lg"
                  target="_blank"
                  href="https://buymeacoff.ee/jmcasebier"
                  rel="noopener noreferrer"
                >
                  <FaCoffee/>
                  Buy me a coffee
                </Button>
                <GridItem xs={12} sm={10} md={8} lg={8} className={classes.itemGrid}>
                  <a href={"https://buymeacoff.ee/jmcasebier"} target={"_blank"}>
                    <img src={bmcButton} alt="..." className={classes.bmcButton} />
                  </a>
                </GridItem>*/}
{/*<Button
                    href="https://twitter.com/jmcasebier"
                    target="_blank"
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaTwitter />
                  </Button>*/}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(TeamSection);
